

















import Vue from 'vue';
import UserListRow from './UserListRow.vue';
export default Vue.extend({
  components: { UserListRow },
  props: {
    users: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    fallbackMessage: {
      type: String,
      required: false,
      default: 'Noch keine Nutzer',
    },
  },
});
