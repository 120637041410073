



























import Vue from 'vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import UserList from '@/components/Mobile/UserList.vue';
import { mapGetters, mapMutations } from 'vuex';
export default Vue.extend({
  components: { Scaffold, UserList },
  data() {
    return {};
  },
  async mounted() {
    this.$store.dispatch('toggleLoading', true);
    await this.$store.dispatch(
      'tour/fetchTourMembers',
      this.$route.params.tourId
    );
    this.$store.dispatch('toggleLoading', false);
  },
  computed: {
    ...mapGetters({
      tourMembers: 'tour/getTourMembers',
    }),
  },
  methods: {
    ...mapMutations({
      resetTourMembers: 'tour/RESET_TOUR_MEMBERS',
    }),
  },
  beforeDestroy() {
    this.resetTourMembers();
  },
});
